$(document).on('click', '.cases-table tr', function (e) {
  var row = $(e.currentTarget)
  var link = $(row).find('a')

  document.location = link.attr('href')
})

$(document).on('click', '.cases-table a', function (e) {
  e.stopPropagation()
})
