/* global FileReader, Blob */

import { DirectUpload } from 'activestorage'

const dragDropSupported = (function () {
  var div = document.createElement('div')
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
}())

const getForm = function (target) {
  let form

  if (target) {
    form = $(target).closest('form')
  }

  if (form && form.length) {
    return form
  } else {
    return $('form:visible')
  }
}

const getFileInput = function (target) {
  const form = getForm(target)
  return form.find('input:file')
}

const hasFileInput = function () {
  return getFileInput().length > 0
}

if (dragDropSupported) {
  $(document).on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
    if (!hasFileInput()) return

    e.preventDefault()
    e.stopPropagation()
  }).on('dragover dragenter', function () {
    if (!hasFileInput()) return
    $('body').addClass('dragover')
  }).on('dragleave dragend drop', function () {
    if (!hasFileInput()) return
    $('body').removeClass('dragover')
  }).on('drop', function (e) {
    const input = getFileInput(e.target)
    console.log(input)
    if (input.length < 1) return

    let droppedFiles = e.originalEvent.dataTransfer.files

    for (var file of droppedFiles) {
      const attachment = new Attachment(file, input[0])
      attachment.beginUpload()
    }
  })
}

$(document).on('change', '[data-behavior="upload"]', function (e) {
  for (var file of this.files) {
    const attachment = new Attachment(file, this)
    attachment.beginUpload()
  }

  this.value = ''
})

$(document).on('click', '[data-behavior="delete-attachment"]', function (e) {
  e.preventDefault()

  const attachment = $(this).closest('.attachment')

  attachment.closest('form').find(':submit').prop('disabled', false)

  if ($(this).data('multiple')) {
    attachment.remove()
  } else {
    attachment.find('input').val('')
    attachment.hide()
  }
})

class Attachment {
  constructor (file, input) {
    this.input = input
    this.file = file

    this.$form = $(input).closest('form')
    this.$container = this.$form.find(input.getAttribute('data-target'))

    this.$el = $(this.element)
    this.$el.data('input', this.input)

    if (input.multiple) {
      this.$container.append(this.$el)
    } else {
      this.$container.html(this.$el)
    }

    this.disableForm()
  }

  beginUpload () {
    this.upload = new DirectUpload(this.file, this.url, this)
    this.upload.create((error, blob) => {
      this.enableForm()

      if (error) {
        console.log(error)
      } else {
        const hiddenInput = `<input type="hidden" name="${this.input.name}" value="${blob.signed_id}" />`
        this.$el.append(hiddenInput)

        this.loadThumbnail((url) => {
          let thumbnail = this.$el.find('.thumbnail')

          thumbnail.find('.progress').remove()
          thumbnail.removeClass('loading')

          if (url) {
            thumbnail.find('img').attr('src', url)
          } else {
            thumbnail.addClass('unknown')
          }
        })
      }
    })
  }

  get element () {
    return `
    <div class="attachment">
      <figure class="thumbnail loading">
        <img src="" />
        <span class="progress" />
      </figure>
      <div class="file-info">
        <label class="filename">${this.filename}</label>
        <p class="filesize">${this.filesize}</p>
      </div>
      <a href="#" class="delete-attachment" data-behavior="delete-attachment" data-multiple="${this.input.multiple}">Delete</a>
    </div>
    `
  }

  get filename () {
    return this.file.name
  }

  get filesize () {
    return humanFileSize(this.file.size)
  }

  get thumbnail () {
    if (this.file.type.match('image')) {
      return URL.createObjectURL(this.file)
    } else {

    }
  }

  get url () {
    return this.input.getAttribute('data-direct-upload-url')
  }

  loadThumbnail (callback) {
    const file = this.file

    if (file.type.match('image')) {
      const url = URL.createObjectURL(file)
      callback(url)
    } else if (file.type.match('video')) {
      const fileReader = new FileReader()

      fileReader.onload = function () {
        const blob = new Blob([fileReader.result], { type: file.type })
        const url = URL.createObjectURL(blob)
        const video = document.createElement('video')

        const timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
            video.pause()
          }
        }

        video.addEventListener('loadeddata', function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
          }
        })

        const snapImage = function () {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

          const image = canvas.toDataURL()
          const success = image.length > 100000

          if (success) {
            callback(image)
            URL.revokeObjectURL(url)
          }

          return success
        }

        video.addEventListener('timeupdate', timeupdate)
        video.preload = 'metadata'
        video.src = url
        video.muted = true
        video.playsInline = true

        video.play()
      }

      fileReader.readAsArrayBuffer(file)
    } else {
      callback()
    }
  }

  disableForm () {
    this.$el.closest('form').find(':submit').prop('disabled', true)
  }

  enableForm () {
    this.$el.closest('form').find(':submit').prop('disabled', false)
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    let progress = event.loaded / event.total
    this.$el.find('.progress').css({ width: `${progress * 100}%` })
  }
}

function humanFileSize (size) {
  var i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}
