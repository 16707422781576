import 'lightcase'

$(function () {
  $('a[data-rel^=lightcase]').lightcase({
    maxWidth: 'none',
    maxHeight: 'none',
    video: {
      width: 1280,
      height: 720,
      poster: '',
      preload: 'auto',
      controls: true,
      autobuffer: true,
      autoplay: true,
      loop: false
    }
  })
})
