$(document).on('change', '[data-behavior="select-plan"]', function () {
  $(this).closest('form').find(':submit').removeAttr('disabled')
})

$(document).on('click', '[data-behavior="billing-period"]', function (e) {
  e.preventDefault()

  const form = $(this).closest('form')
  const period = $(this).data('period')

  form.removeClass('annual monthly')
  form.addClass(period)

  form.find('input[name="plan"]').prop('checked', false)
  form.find(':submit').attr('disabled', 'disabled')
})
