import 'rangeslider.js'

const numberWithCommas = (x) => x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const formatCurrency = (cents) => '$' + numberWithCommas(cents / 100)

const updateOrderTotal = () => {
  const totals = $('input[type="range"]').map((i, el) => $(el).data('total')).get()
  const sum = totals.reduce((total, val) => total + val)

  $('#order-total').text(formatCurrency(sum))
}

const updateSlider = (slider) => {
  const quantity = slider.value

  slider.$handle.text(quantity)

  const price = slider.$element.data('price')
  const discount5x = parseFloat(slider.$element.data('discount-5x'))
  const discount10x = parseFloat(slider.$element.data('discount-10x'))

  let subTotal = quantity * price
  let total = subTotal
  let discountText

  if (quantity >= 10 && discount10x > 0) {
    total = subTotal * (1 - discount10x)
    discountText = `(${discount10x * 100}% bundle discount)`
  } else if (quantity >= 5 && discount5x > 0) {
    total = subTotal * (1 - discount5x)
    discountText = `(${discount5x * 100}% bundle discount)`
  } else {
    total = subTotal
  }

  slider.$element.data('total', total)

  const display = slider.$range.closest('.product-slider').find('.price')
  display.text(formatCurrency(total))
  if (discountText) display.append(`<small>${discountText}</small>`)

  updateOrderTotal()
}

$(function () {
  $('input[type="range"]').rangeslider({
    polyfill: false,
    onInit: function () {
      updateSlider(this)
    },
    onSlide: function (position, value) {
      updateSlider(this)
    }
  })
})
