export const stripeStyles = () => {
  const control = $('input.form-control')

  return {
    base: {
      lineHeight: 1.5,
      fontSize: control.css('fontSize'),
      fontWeight: control.css('fontWeight'),
      fontFamily: control.css('fontFamily'),
      color: control.css('color')
    }
  }
}

export const mountStripeFields = (elements) => {
  const style = stripeStyles()

  const cardExpiry = elements.create('cardExpiry', { style: style })
  cardExpiry.mount('#card-expiry')

  const cardNumber = elements.create('cardNumber', { style: style })
  cardNumber.mount('#card-number')

  const cardCvc = elements.create('cardCvc', { placeholder: '', style: style })
  cardCvc.mount('#card-cvv')

  return cardNumber
}

export const setupPaymentMethods = (form) => {
  const target = $('#new-payment-method')

  form.find('[data-toggle="new-payment-method"]').on('change', function () {
    const visibility = $(this).data('visibility')
    target.toggle(visibility === 'show')
  })
}

export const getPaymentMethod = (card) => {
  const selectedPaymentMethod = $('input[data-payment-method]:checked').val()

  if (selectedPaymentMethod == null || selectedPaymentMethod === 'new') {
    return {
      card: card,
      billing_details: {
        name: $('#card-name').val()
      }
    }
  } else {
    return selectedPaymentMethod
  }
}
