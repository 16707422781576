$(document).on('click', '[data-toggle="reply"]', function (e) {
  e.preventDefault()

  const target = $($(this).data('target'))
  target.toggle('show')

  const buttons = $($(this).data('buttons') || '.post-buttons')
  buttons.toggle('hide')
})

$(document).on('click', '[data-toggle="cancel-reply"]', function (e) {
  e.preventDefault()

  const buttons = $($(this).data('buttons') || '.post-buttons')
  buttons.toggle('show')

  $(this).closest('.collapse').toggle('hide')
})

$(document).on('input focus blur', '.other-input input', function (e) {
  const container = $(this).closest('.form-check')
  const checkbox = container.find('.toggle-other')

  checkbox.prop('checked', $(this).val() !== '')
})

$(document).on('click', '.other-input', function (e) {
  const input = $(this).find('.form-control')

  if (input.is(':disabled')) {
    input.prop('disabled', false).focus()
  }
})

$(document).on('click', '.toggle-other', function (e) {
  const container = $(this).closest('.form-check')
  const input = container.find('.form-control')

  input.prop('disabled', !$(this).is(':checked'))
})
