/* global alert, Stripe */

import Rails from 'rails-ujs'

import { mountStripeFields, setupPaymentMethods, getPaymentMethod } from './fields'

const setupEventHandler = (stripe, card, form) => {
  const enableForm = () => Rails.enableElement(form.find(':submit')[0])

  const handleError = (error) => {
    alert(error.message)
    setTimeout(enableForm, 50)
  }

  const handleSuccess = (setupIntent) => {
    $('<input type="hidden" name="payment[stripe_setup_intent]" value="' + setupIntent.id + '" />').appendTo(form)

    // By-pass submit handler
    form[0].submit()
  }

  form.on('submit', function (e) {
    e.preventDefault()

    stripe.confirmCardSetup(form.data('stripe-setup-intent'), {
      payment_method: getPaymentMethod(card)
    }).then(function ({ error, setupIntent }) {
      if (error && error.message) {
        handleError(error)
      } else {
        if (setupIntent.status === 'succeeded') {
          handleSuccess(setupIntent)
        }
      }
    })
  })
}

const attachStripe = (form) => {
  const stripe = Stripe(form.data('stripe-public-key'))
  const card = mountStripeFields(stripe.elements())
  setupEventHandler(stripe, card, form)
}

$(function () {
  const form = $('#subscribe-form')

  if (form.length) {
    attachStripe(form)
    setupPaymentMethods(form)
  }
})
