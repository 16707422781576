import $ from 'jquery'
global.$ = global.jQuery = jQuery

import Rails from 'rails-ujs'

import 'bootstrap/dist/js/bootstrap'
import '@nathanvda/cocoon'

import './components/header'
import './components/purchase'
import './components/stripe'
import './components/attachments'
import './components/cases'
import './components/tabs'
import './components/plans'
import './components/upgrade'
import './components/instagram'
import './components/blog'

import 'trix'

Rails.start()

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

document.addEventListener('trix-file-accept', function (e) {
  e.preventDefault()
})
