import Instafeed from 'instafeed.js'

const INSTAGRAM_TOKEN_API_URL = 'https://ig.instant-tokens.com/users/eb531da3-6744-4b5a-9fcf-04e6d75c38ae/instagram/17841414731793608/token?userSecret=n9i04espqyfzmww6yvz5bq'

const TEMPLATE = `
  <div class="col">
    <a href="{{link}}" target="_blank">
      <img src="{{image}}" alt="{{caption}}" />
    </a>
  </div>`

$(function () {
  if ($('#instagram').length > 0) {
    var feed = new Instafeed({
      target: 'instagram',
      accessToken: function (done) {
        $.ajax({
          url: INSTAGRAM_TOKEN_API_URL,
          dataType: 'json'
        }).done(function (response) {
          if (response.Token) {
            done(null, response.Token)
          } else {
            done(new Error('error: ' + response))
          }
        })
      },
      template: TEMPLATE,
      limit: 10
    })

    feed.run()
  }
})
