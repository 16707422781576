import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

$(function () {
  if ($('.blog-index').length > 0) {
    imagesLoaded('.blog-index', () => {
      new Masonry('.blog-index')
    })
  }
})
