$(document).on('shown.bs.tab', '[data-behavior="disable-fields"]', function () {
  const pane = $($(this).attr('href'))
  const others = pane.siblings()

  pane.find('input, select').attr('disabled', false)
  others.find('input, select').attr('disabled', true)
})

$(function () {
  $('[data-behavior="disable-fields"]:not(.active)').each(function () {
    const pane = $($(this).attr('href'))
    pane.find('input, select').attr('disabled', true)
  })
})
